<template>
  <div id="app">
    <inline-style-loader></inline-style-loader>
    <b-container v-if="hasImporteur">
      <pageheader title="Dashboard" v-if="hasAutorisatie(Autorisatie.GebruikerInloggen)"></pageheader>
      <router-view :key="$route.fullPath"></router-view>
    </b-container>
    <div class="debugViewport" v-if="showViewport && hasImporteur">
      Viewport
      <span class="d-block d-sm-none">XS</span>
      <span class="d-none d-sm-block d-md-none">SM</span>
      <span class="d-none d-md-block d-lg-none">MD</span>
      <span class="d-none d-lg-block d-xl-none">LG</span>
      <span class="d-none d-xl-block">XL</span>
    </div>
    <notifications/>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Pageheader from "./components/Pageheader.vue";
import InlineStyleLoader from "./components/InlineStyleLoader.vue";
import Notifications from "./components/Notifications.vue";
import { Dispatcher } from "vuex-type-helper";
import { ApplicationActions } from "./store";
import includes from "lodash-es/includes";
import { Autorisatie } from "./Autorisatie";

export default Vue.extend({
  components: {
    Pageheader,
    Notifications,
    InlineStyleLoader
  },
  data() {
    return {
      Autorisatie: Autorisatie
    };
  },
  computed: {
    importeur(): string | null {
      return this.$store.getters.importeurNaam;
    },
    locale(): string | null {
      return this.$store.getters.locale;
    },
    hasImporteur(): boolean {
      return !!this.importeur;
    },
    showViewport(): boolean {
      return (
        includes(location.host, "-test.vijzelmolen") ||
        includes(location.host, "localhost")
      );
    }
  },
  created() {
    this.$store.dispatch<Dispatcher<ApplicationActions>>({
      type: "initStore"
    });
  },
  methods: {
    hasAutorisatie(autorisatie: number): boolean {
      return this.$store.getters.hasAutorisatie(autorisatie);
    },

    setFavicon(url: string) {
      const removeFavicon = () => {
        let links = document.getElementsByTagName("link");
        let head = document.getElementsByTagName("head")[0];
        for (let i = 0; i < links.length; i++) {
          if (links[i].getAttribute("rel") === "icon") {
            head.removeChild(links[i]);
          }
        }
      };

      removeFavicon();

      let link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "icon";
      link.href = url;
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  },
  watch: {
    importeur: function(val) {
      if (!!val) this.setFavicon("favicon" + val + ".ico");
    },
    locale: function(locale) {
      if (!!locale) this.$i18n.locale = locale;
    }
  }
});
</script>

<style lang="scss">
//ipv bootstrap/dist/css/bootstrap.min.css te importeren de scss versie gebruiken en deze te compilen.

// Zie node_modules/bootstrap/scss/_variables.scss voor de lijst
// via SCSS kunnen de bootstrap variabelen overriden worden. Scheelt extra CSS overrides en !important workarounds
$font-family-base: "clear_sans_regular";
$body-bg: #f7f7f7;
$font-size-base: 0.875rem; // effectief 14px, maar de px unit kunnen we niet gebruiken omdat Bootstrap er mee gaat rekenen


@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

@import "~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

@import "~devextreme/dist/css/dx.common.css";
@import "~devextreme/dist/css/dx.material.lime.light.css";

.debugViewport {
  color: #ccc;
  font-size: 8pt;
  text-align: center;
  margin-top: 20px;
}
</style>
