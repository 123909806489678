<template>
  <b-navbar toggleable="sm" variant="dark" fixed="top" class="navbar-custom">
    <b-navbar-brand class="navbar-brand-custom d-none d-sm-block" to="/">
      <b-img :src="iconPath" class="iconimg" />
      <span class="applicatie">Dashboard</span>
    </b-navbar-brand>
    <b-img :src="iconPath" class="iconimg d-block d-sm-none" />

    <!-- Right aligned nav items -->
    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown right no-caret>
        <!-- Using button-content slot -->
        <template slot="button-content">
          <i class="fas fa-lg menufont">{{$t('leads')}}</i>
        </template>

        <b-dropdown-item
          v-if="hasAutorisatie(Autorisatie.LeadDashboard)"
          :to="{ name: 'leads'}"
          :class="{'nav-active': navIsDashboard}"
        >{{$t('dashboard')}}</b-dropdown-item>
        <b-dropdown-item
          v-if="hasAutorisatie(Autorisatie.LeadDashboard)"
          :to="{ name: 'leadsgrid'}"
        >{{$t('pivotgrid')}}</b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown right no-caret>
        <!-- Using button-content slot -->
        <template slot="button-content">
          <i class="fas fa-lg menufont">{{$t('orders')}}</i>
        </template>

        <b-dropdown-item
          v-if="hasAutorisatie(Autorisatie.DashboardOrders)"
          :to="{ name: 'orders'}"
          :class="{'nav-active': navIsOrderDashboard}"
        >{{$t('dashboard')}}</b-dropdown-item>
        <b-dropdown-item
          v-if="hasAutorisatie(Autorisatie.DashboardOrders)"
          :to="{ name: 'ordersgrid'}"
        >{{$t('pivotgrid')}}</b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown right no-caret>
        <!-- Using button-content slot -->
        <template slot="button-content">
          <div class="nav-user">
            <i class="fas fa-bars fa-lg"></i>
          </div>
        </template>

        <b-dropdown-item v-if="navIsReports" :to="{ name: 'reports'}">{{$t('rapportage')}}</b-dropdown-item>
        <b-dropdown-item
          v-if="hasAutorisatie(Autorisatie.BeheerUsers)"
          :to="{ name: 'users'}"
        >{{$t('beheer')}}</b-dropdown-item>
        <b-dropdown-item v-if="navIsAdmin" :to="{ name: 'configuration'}">{{$t('configuration')}}</b-dropdown-item>
        <b-dropdown-item :to="{name: 'changepassword'}">{{$t('changepassword')}}</b-dropdown-item>
        <b-dropdown-item @click="switchLang">{{otherLang}}</b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item :to="{ name: 'logout'}">{{$t('logout')}}</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script lang="ts">
import Vue from "vue";
import { ApplicationActions } from "../store";
import { Dispatcher, Committer } from "vuex-type-helper";
import { Autorisatie } from "../Autorisatie";

export default Vue.extend({
  props: {
    title: String
  },
  data() {
    return {
      Autorisatie: Autorisatie
    };
  },
  computed: {
    navIsDashboard(): boolean {
      return this.$route.name === "leads";
    },
    navIsReports(): boolean {
      return (
        this.hasAutorisatie(Autorisatie.RapportageLeads) ||
        this.hasAutorisatie(Autorisatie.RapportageOrders)
      );
    },
    navIsAdmin(): boolean {
      return this.$store.getters.isAdmin;
    },
    navIsOrderDashboard(): boolean {
      return this.$route.name === "orders";
    },
    importeur(): string | null {
      return this.$store.getters.importeurNaam;
    },
    hasImporteur(): boolean {
      return !!this.importeur;
    },
    iconPath(): string {
      return this.hasImporteur
        ? "/images/icon" + this.importeur + ".png"
        : "images/icon.png";
    },
    otherLang(): string {
      if (this.$i18n.locale === "nl") return "English";

      return "Nederlands";
    }
  },
  methods: {
    hasAutorisatie(autorisatie: number): boolean {
      return this.$store.getters.hasAutorisatie(autorisatie);
    },
    switchLang() {
      const newLocale = this.$i18n.locale === "nl" ? "en" : "nl";
      this.$store.dispatch<Dispatcher<ApplicationActions>>({
        type: "setLocale",
        locale: newLocale
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.menufont {
  font-family: "clear_sans_regular";
  font-size: 0.875rem;
  font-weight: 400;
}

.nav-item:hover .menufont {
  color: black;
}
</style>
 
