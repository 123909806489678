<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "InlineStyle",
  functional: true,
  render(createElement, context) {
    return createElement("style", context.children);
  }
});
</script>