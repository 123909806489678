<template>
  <div class="home"></div>
</template>

<script lang="ts">
import Vue from "vue";
import { Autorisatie } from "../Autorisatie";

export default Vue.extend({
  mounted() {
    let routerName = this.$store.getters.defaultPage();
    this.$router.push({ name: routerName });
  }
});
</script>

 

 